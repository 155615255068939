<template>
    <ContentWrapper>
        <breadcrumb :name="$t('common.view.settings.personal_settings')"/>

        <div class="container">
            <div class="row">
                <div class="col-xl-3">
                    <div class="card card-default animated-fast fadeInLeft">
                        <div class="card-header">{{$t('common.view.settings.personal_settings')}}<i class="card-header-icon fad fa-cog"></i></div>
                        <div class="card-body p-0 card-body-solid">
                            <div class="list-group">
                                <div class="list-group-item text-muted text-sm">Account</div>
                                <router-link active-class="active" :to="{name: 'SettingsAddress'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-address-card"></i>{{$t('common.view.settings.menu.address')}}</router-link>
                                <router-link active-class="active" :to="{name: 'SettingsAccount'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-key"></i>{{$t('common.view.settings.menu.password')}}</router-link>
                                <router-link active-class="active" :to="{name: 'SettingsPublicProfile'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-user"></i>{{$t('common.view.settings.menu.profile')}}</router-link>
                                <router-link active-class="active" :to="{name: 'Growing:SettingsMarket'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-exchange"></i>{{$t('common.view.settings.menu.market')}}</router-link>
                                <router-link active-class="active" :to="{name: 'Growing:SettingsPlants'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-seedling"></i>{{$t('common.view.settings.menu.plants')}}</router-link>
                                <router-link active-class="active" :to="{name: 'SettingsVerification'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-file"></i>{{$t('common.view.settings.menu.verification')}}</router-link>
                                <router-link active-class="active" :to="{name: 'SettingsLanguage'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-globe"></i>{{$t('common.view.settings.menu.language')}}</router-link>
                                <router-link active-class="active" :to="{name: 'Settings2fa'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-mobile-android-alt"></i>{{$t('common.view.settings.menu.two-factor-auth')}}</router-link>
                                <router-link active-class="active" :to="{name: 'SettingsDevices'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-laptop"></i>{{$t('common.view.settings.menu.devices')}}</router-link>
                                <router-link active-class="active" :to="{name: 'SettingsNotifications'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-bell"></i>{{$t('common.view.settings.menu.notifications')}}</router-link>
                                <router-link active-class="active" :to="{name: 'SettingsTerms'}" class="list-group-item list-group-item-action" href=""><i class=" mr-2 fad fa-list"></i>{{$t('common.view.settings.menu.terms')}}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-9 animated-fast fadeInUp">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </ContentWrapper>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        mounted() {
        },
    }
</script>